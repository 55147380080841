.booking {
  color: #000;
  margin-left: 35px;
}
.booking .slider-basic {
  height: calc(100vw - 30px);
  margin-bottom: 15px;
}
.booking .headlines {
  margin-top: 20px;
}
.booking .headline {
  text-align: left;
  font-family: NanumGothic;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -0.02em;
}
.booking .intro {
  font-family: HK Grotesk Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-bottom: 50px;
  margin-top: 20px;
  color: #828282;
}
.booking .SquareButton,
.booking .PhilosophyButton {
  position: absolute;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=src/views/Booking.css.map */