.PhilosophyButton {
  padding: 20px;
  background: #fff;
  box-shadow: -18px 18px 63px rgba(8,181,255,0.09), -4.02px 4.02054px 14.0719px rgba(8,181,255,0.054), -1.2px 1.19702px 4.18956px rgba(8,181,255,0.036);
  margin-bottom: 10px;
  border-radius: 20px;
  color: #000;
  margin-right: 35px;
  height: calc(100vw - 70px);
  width: calc(100vw - 105px);
  text-align: center;
  position: relative;
  will-change: transform, opacity;
}
.PhilosophyButton .goals {
  text-align: left;
  margin-top: 45px;
  font-family: HK Grotesk Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
}
.PhilosophyButton .goal {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(46,46,46,0.1);
  padding-left: 25px;
  color: #2e2e2e;
  position: relative;
  max-width: 200px;
}
.PhilosophyButton .goal:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 11px;
  height: 11px;
  background: url("/img/views/booking/philosophy-dot.svg");
  background-repeat: none;
}
.PhilosophyButton .more {
  margin-top: 35px;
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #0097fe;
}
.PhilosophyButton .title {
  font-family: Neue Montreal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}
.PhilosophyButton .illustration {
  position: absolute;
  top: 30px;
  right: -170px;
  width: 281px;
  height: 241px;
  background: url("/img/views/booking/philosophy-plate.png");
  background-size: cover;
  background-repeat: none;
}
/*# sourceMappingURL=src/components/booking/PhilosophyButton.css.map */