.SquareButton {
  padding: 20px;
  background: linear-gradient(180deg, rgba(0,151,254,0.5) 0%, #0097fe 100%);
  box-shadow: 0px 41.7776px 33.4221px rgba(8,181,255,0.05), 0px 22.3363px 17.869px rgba(8,181,255,0.042), 0px 12.5216px 10.0172px rgba(8,181,255,0.035), 0px 6.6501px 5.32008px rgba(8,181,255,0.028), 0px 2.76726px 2.21381px rgba(8,181,255,0.02);
  border-radius: 20px;
  user-select: none;
  margin-bottom: 10px;
  color: #fff;
  margin-right: 35px;
  height: calc(100vw - 70px);
  will-change: transform, opacity;
}
.SquareButton .illustration {
  margin-top: 30px;
  width: calc(100% - 50px);
  height: auto;
}
.SquareButton .title {
  font-family: NanumGothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.SquareButton .subtitle {
  font-family: NanumGothic;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 30px;
}
.SquareButton .desc {
  margin-top: 10px;
  font-family: 'HK Grotesk Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.03em;
  text-align: left;
}
.SquareButton h4 {
  margin-top: 10px;
  color: #333;
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
}
.SquareButton.darkMode {
  color: #000;
}
.SquareButton.darkMode .desc {
  color: #828282;
}
/*# sourceMappingURL=src/components/booking/SquareButton.css.map */