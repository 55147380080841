
.booking
	color #000
	margin-left 35px
	.slider-basic
		height calc(100vw - 30px)
		margin-bottom 15px
	.headlines
		margin-top 20px
	.headline
		text-align left
		font-family: NanumGothic;
		font-size: 35px;
		font-style: normal;
		font-weight: 700;
		line-height: 39px;
		letter-spacing: -0.02em;
	.intro
		font-family: HK Grotesk Pro;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: -0.03em;
		text-align: left;
		margin-bottom: 50px
		margin-top 20px
		color rgba(130, 130, 130, 1)

	.SquareButton,.PhilosophyButton
		position absolute
		top 0
		left 0

