
	.pager {
		overflow: hidden;
		position: relative;
		border-radius: 5px;
		margin auto
	}

	.inside {
		position: absolute;
		display: inline-block;
		border-radius: 5px;
		top:0;
		left:0;
	}
